/*
 * Copyright (C) Yağızhan IŞIK - 2022.
 * This app is developed for internal use only and may not be exported, shared, edited or distributed under any circumstances.
 */

@import url('/styles/inter/inter.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Round');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'); */

html {
	font-family : 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
	html {
		font-family : 'Inter var', sans-serif;
	}
}

body {
	background-color : #FFFFFF;
	color            : #211C1D;
}

* {
	box-sizing : border-box;
	margin     : 0;
	padding    : 0;
}

.bg-pattern {
	background-image : url('/assets/koseoglu-cover.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	height           : 100vh;
	width            : 60vw;
}

.login-flex {
	align-items     : center;
	display         : flex;
	justify-content : center;
}

.login-box {
	font-size   : 0.8rem;
	height      : 100vh;

	padding     : 3em;
	position    : relative;
	width       : 40vw;
}

.welcome-title {
	font-size  : 2em;
	margin-top : 3em;
}

.welcome-text {
	margin-top : 4rem;
}

.login-text-boxes {
	margin-top : 3rem;

}

.login-text-boxes > input {
	-webkit-backdrop-filter  : blur(15px) brightness(50%);
	backdrop-filter          : blur(15px) brightness(30%);
	-webkit-background-color : transparent;
	border                   : 1px solid #B8B8B8;
	color                    : #222222;
	display                  : block;
	font-size                : 1.1em;
	margin-top               : 1em;
	outline                  : none;
	padding                  : 2%;
	width                    : 100%;
}

.fp-btn {
	margin-top: 1rem;
	display: block;
	color: black;
}

.login-btn {
	background-color : #1132C7;
	border-radius    : 4px;
	color            : white;
	cursor           : pointer;
	font-size        : 1em;
	margin-top       : 2em;
	padding-bottom   : 0.8em;
	padding-top      : 0.8em;
	text-align       : center;
	transition       : background-color 0.2s ease;
	width            : 25%;
}

.login-btn:hover {
	background-color : #0C238B;
}

.secure-login-info {
	color       : #008B38;
	font-size   : 1.1em;
	font-weight : 500;
	margin-top  : 5rem;
	text-align  : center;
}

.secure-login-info > i {
	display      : inline-block;
	margin-right : 1ch;
}

.secure-login-learn-more {
	color      : #0E0004CC;
	display    : block;
	margin-top : 1rem;
	text-align : center;
}

.copyrights-login {
	bottom     : 2rem;
	left       : 50%;
	position   : absolute;
	text-align : center;
	transform  : translateX(-50%);
	width      : 90%;
}

.copyright-company {
	font-size : 0.86rem;
}

.copyright-sfkygz {
	color     : #888888;
	font-size : 0.75rem;
}

.login-icon {

}

.login-icon > svg {
	animation  : login-icon-load 1s linear infinite;
	margin-top : 2rem;
	width      : 2rem;
}

.dn {
	display : none !important;

}

@keyframes login-icon-load {
	0% {
		transform : rotate(0deg);
	}
	100% {
		transform : rotate(360deg);
	}
}

.app-wrap {

	padding-bottom : 0;
	padding-left   : 0;
}

.app-name {
	font-size   : 1.25em;
	font-weight : 500;
}

.app-company {
	font-size  : .8em;
	margin-top : .5em;
}

.app-wrap > .top-nav {
	align-items     : center;
	display         : flex;
	justify-content : space-between;
}

.tn-r {
	text-align : right;
}

.tn-r > .user-code {
	color      : #CCCCCC;
	font-size  : .8em;
	margin-top : .5em;
}

.nav-title {
	font-size   : 1.25em;
	font-weight : 600;
	text-align  : center;
}

nav {
	border-bottom : 1px solid #888888;
	display       : none !important;
	font-size     : .9rem;
	padding       : 1.25em;
	padding-top   : 0;
}

.app-flex {
	align-items     : center;

	display         : flex;
	height          : 100vh;
	justify-content : right;
	margin-left     : auto;
	padding-bottom  : 0;
	padding-left    : 0;
	width           : 85vw;
}

.app-flex > .right-content {
	height : 90%;
	width  : 80vw;
}

.branding > .branding-company {
	font-size   : 1.1em;
	font-weight : 700;
}

.branding > .branding-tys {
	font-size   : .9em;
	font-weight : 400;
	margin-top  : .5em;
}

.left-bar {

	background-color : #DCD7EA;
	font-size        : .8rem;
	height           : 100vh;
	left             : 0;
	overflow         : hidden;
	overflow-y       : auto;
	padding          : 3rem;
	position         : fixed;
	top              : 0;
	width            : 20vw;
	z-index          : 9;
}

.left-bar > .icons {
	align-items     : stretch;
	color           : #5B34EA;
	display         : flex;
	flex-direction  : column;
	justify-content : left;
	margin-top      : 3rem;

}

.icn {
	cursor     : pointer;
	margin-top : 10%;
	width      : min-content;
}

.app-flex > .right-content > iframe {
	border-radius : 10px;
	height        : 100%;
	width         : 100%;

}

.icn {
	align-items     : center;
	border-radius   : 9999px;
	cursor          : pointer;
	display         : inline-flex;
	justify-content : left;
	padding         : .5rem;
	position        : relative;
	transition      : background-color 0.2s ease;
	width           : 100%;
}

.icn:hover {
	background-color : #B6B2C2;

}

.icn-sect {
	margin-bottom : 2rem;
}

.icn-sect-up {
	margin-top : 2rem;
}

.icn > .hover-text {

	color          : black;
	margin-left    : 2ch;
	pointer-events : none;
	transition     : opacity .3s ease;
	width          : max-content;
}

.icn.icn-selected > .hover-text {
	color : white;
}

.icn-selected {
	background-color : #5B34EA;
	border-radius    : 9999px;
	color            : white;
	font-weight      : 500;
	width            : 100%;

}
.pw-warn{
	text-align: center;
	margin-top: 1rem;
	color: red;
}

/*iframe debug*/
.app-flex > .right-content > iframe.db-border {
	border : 1px solid red;
}

.sc-btn {
	align-items     : center;
	display         : flex;
	justify-content : center;
}

.sc-btn > .btn-txt {
	margin-left : 1rem;
}

.sc-wrap {
	margin-left  : 5vw;
	margin-right : 5vw;
	margin-top   : 5vh;
}

.sc-title {
	font-size     : 1.5rem;
	font-weight   : 700;
	margin-bottom : 1rem;
}

.sc-body {
	color : #666666;
}

.search-box {
	border        : 2px solid #5B34EA;
	border-radius : 9999px;
	font-size     : 1.1em;
	margin-top    : 1rem;
	padding       : 1rem;
	width         : 50%;
}

@media screen and (min-width : 850px) {
	.icn > .hover-text {
		opacity : 1 !important;
	}

}
@media screen and (max-width: 600px) {
	.login-box {
		width: 100vw;
	}
	.bg-pattern {
		display: none;
	}
	.welcome-title{
		line-height: 2rem;
	}
	.copyrights-login {
		position: relative;
		bottom: unset;
		left: unset;
		transform: unset;
		width: 100%;
		margin-top: 2rem;
		padding-bottom: 3rem;
	}
	.login-btn {
		width: 100%;
	}
}