@import "https://fonts.googleapis.com/icon?family=Material+Icons+Round";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../Inter-Thin.30fa2792.woff2") format("woff2"), url("../Inter-Thin.509b3195.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../Inter-ThinItalic.baaa147a.woff2") format("woff2"), url("../Inter-ThinItalic.305c6c76.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../Inter-ExtraLight.964a821e.woff2") format("woff2"), url("../Inter-ExtraLight.9bd9159b.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("../Inter-ExtraLightItalic.8892db5f.woff2") format("woff2"), url("../Inter-ExtraLightItalic.a68f8177.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../Inter-Light.c06f5e9d.woff2") format("woff2"), url("../Inter-Light.5feaf686.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../Inter-LightItalic.9361988e.woff2") format("woff2"), url("../Inter-LightItalic.59e166b6.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../Inter-Regular.449f0e8a.woff2") format("woff2"), url("../Inter-Regular.e79139c9.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../Inter-Italic.26f3dc3f.woff2") format("woff2"), url("../Inter-Italic.9092ffa2.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../Inter-Medium.acc3d50f.woff2") format("woff2"), url("../Inter-Medium.cfcc1d28.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../Inter-MediumItalic.078088d6.woff2") format("woff2"), url("../Inter-MediumItalic.4aa3953c.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../Inter-SemiBold.bdb1c7ad.woff2") format("woff2"), url("../Inter-SemiBold.fa7f9547.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../Inter-SemiBoldItalic.2550cf4d.woff2") format("woff2"), url("../Inter-SemiBoldItalic.fb0578b1.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../Inter-Bold.53ea85ae.woff2") format("woff2"), url("../Inter-Bold.d03e3d36.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../Inter-BoldItalic.2c92d711.woff2") format("woff2"), url("../Inter-BoldItalic.4ca4d3bd.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../Inter-ExtraBold.3eeb4074.woff2") format("woff2"), url("../Inter-ExtraBold.0d648a7c.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../Inter-ExtraBoldItalic.1abc120e.woff2") format("woff2"), url("../Inter-ExtraBoldItalic.6beaf16d.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../Inter-Black.1da350e0.woff2") format("woff2"), url("../Inter-Black.0258c5ed.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../Inter-BlackItalic.f3c01519.woff2") format("woff2"), url("../Inter-BlackItalic.2de967a4.woff") format("woff");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("../Inter-roman.var.b695afbe.woff2") format("woff2");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("../Inter-italic.var.69eb0fe1.woff2") format("woff2");
}

@font-face {
  font-family: Inter var experimental;
  font-weight: 100 900;
  font-display: swap;
  src: url("../Inter.var.5f2f8893.woff2") format("woff2");
}

html {
  font-family: Inter, sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: Inter var, sans-serif;
  }
}

body {
  color: #211c1d;
  background-color: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.bg-pattern {
  height: 100vh;
  width: 60vw;
  background-image: url("../koseoglu-cover.3265a8ff.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.login-flex {
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-box {
  height: 100vh;
  width: 40vw;
  padding: 3em;
  font-size: .8rem;
  position: relative;
}

.welcome-title {
  margin-top: 3em;
  font-size: 2em;
}

.welcome-text {
  margin-top: 4rem;
}

.login-text-boxes {
  margin-top: 3rem;
}

.login-text-boxes > input {
  -webkit-backdrop-filter: blur(15px) brightness(50%);
  -webkit-backdrop-filter: blur(15px) brightness(30%);
  backdrop-filter: blur(15px) brightness(30%);
  -webkit-background-color: transparent;
  color: #222;
  width: 100%;
  border: 1px solid #b8b8b8;
  outline: none;
  margin-top: 1em;
  padding: 2%;
  font-size: 1.1em;
  display: block;
}

.fp-btn {
  color: #000;
  margin-top: 1rem;
  display: block;
}

.login-btn {
  color: #fff;
  cursor: pointer;
  text-align: center;
  width: 25%;
  background-color: #1132c7;
  border-radius: 4px;
  margin-top: 2em;
  padding-top: .8em;
  padding-bottom: .8em;
  font-size: 1em;
  transition: background-color .2s;
}

.login-btn:hover {
  background-color: #0c238b;
}

.secure-login-info {
  color: #008b38;
  text-align: center;
  margin-top: 5rem;
  font-size: 1.1em;
  font-weight: 500;
}

.secure-login-info > i {
  margin-right: 1ch;
  display: inline-block;
}

.secure-login-learn-more {
  color: #0e0004cc;
  text-align: center;
  margin-top: 1rem;
  display: block;
}

.copyrights-login {
  text-align: center;
  width: 90%;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}

.copyright-company {
  font-size: .86rem;
}

.copyright-sfkygz {
  color: #888;
  font-size: .75rem;
}

.login-icon > svg {
  width: 2rem;
  margin-top: 2rem;
  animation: 1s linear infinite login-icon-load;
}

.dn {
  display: none !important;
}

@keyframes login-icon-load {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.app-wrap {
  padding-bottom: 0;
  padding-left: 0;
}

.app-name {
  font-size: 1.25em;
  font-weight: 500;
}

.app-company {
  margin-top: .5em;
  font-size: .8em;
}

.app-wrap > .top-nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.tn-r {
  text-align: right;
}

.tn-r > .user-code {
  color: #ccc;
  margin-top: .5em;
  font-size: .8em;
}

.nav-title {
  text-align: center;
  font-size: 1.25em;
  font-weight: 600;
}

nav {
  border-bottom: 1px solid #888;
  padding: 0 1.25em 1.25em;
  font-size: .9rem;
  display: none !important;
}

.app-flex {
  height: 100vh;
  width: 85vw;
  justify-content: right;
  align-items: center;
  margin-left: auto;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
}

.app-flex > .right-content {
  height: 90%;
  width: 80vw;
}

.branding > .branding-company {
  font-size: 1.1em;
  font-weight: 700;
}

.branding > .branding-tys {
  margin-top: .5em;
  font-size: .9em;
  font-weight: 400;
}

.left-bar {
  height: 100vh;
  width: 20vw;
  z-index: 9;
  background-color: #dcd7ea;
  padding: 3rem;
  font-size: .8rem;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.left-bar > .icons {
  color: #5b34ea;
  flex-direction: column;
  justify-content: left;
  align-items: stretch;
  margin-top: 3rem;
  display: flex;
}

.icn {
  cursor: pointer;
  width: min-content;
  margin-top: 10%;
}

.app-flex > .right-content > iframe {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.icn {
  cursor: pointer;
  width: 100%;
  border-radius: 9999px;
  justify-content: left;
  align-items: center;
  padding: .5rem;
  transition: background-color .2s;
  display: inline-flex;
  position: relative;
}

.icn:hover {
  background-color: #b6b2c2;
}

.icn-sect {
  margin-bottom: 2rem;
}

.icn-sect-up {
  margin-top: 2rem;
}

.icn > .hover-text {
  color: #000;
  pointer-events: none;
  width: max-content;
  margin-left: 2ch;
  transition: opacity .3s;
}

.icn.icn-selected > .hover-text {
  color: #fff;
}

.icn-selected {
  color: #fff;
  width: 100%;
  background-color: #5b34ea;
  border-radius: 9999px;
  font-weight: 500;
}

.pw-warn {
  text-align: center;
  color: red;
  margin-top: 1rem;
}

.app-flex > .right-content > iframe.db-border {
  border: 1px solid red;
}

.sc-btn {
  justify-content: center;
  align-items: center;
  display: flex;
}

.sc-btn > .btn-txt {
  margin-left: 1rem;
}

.sc-wrap {
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}

.sc-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.sc-body {
  color: #666;
}

.search-box {
  width: 50%;
  border: 2px solid #5b34ea;
  border-radius: 9999px;
  margin-top: 1rem;
  padding: 1rem;
  font-size: 1.1em;
}

@media screen and (min-width: 850px) {
  .icn > .hover-text {
    opacity: 1 !important;
  }
}

@media screen and (max-width: 600px) {
  .login-box {
    width: 100vw;
  }

  .bg-pattern {
    display: none;
  }

  .welcome-title {
    line-height: 2rem;
  }

  .copyrights-login {
    bottom: unset;
    left: unset;
    transform: unset;
    width: 100%;
    margin-top: 2rem;
    padding-bottom: 3rem;
    position: relative;
  }

  .login-btn {
    width: 100%;
  }
}

/*# sourceMappingURL=index.82cc4f17.css.map */
